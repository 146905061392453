import html2canvas from "html2canvas";

export class ImageCaptureService {

    /**
     * Captures an HTML element into a reusable canvas using html2canvas.
     * @param element - The HTML element to capture.
     * @returns A Promise resolving to the rendered canvas.
     */
    async captureElementToCanvas(element: HTMLElement): Promise<HTMLCanvasElement> {
        // Capture the element into a canvas
        const canvas = await html2canvas(element, {
            useCORS: true, // Ensures cross-origin images can be rendered
            scale: 1,      // Ensures consistent resolution
            logging: false // Suppresses logs
        });

        return canvas;
    }
}
