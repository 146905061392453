import { initEventType } from "../init-event-type";

export interface PuzzleRestartedEventData {
    puzzleId: string;
    type: string;
    numPieces: number;
    numMoves: number;
}

export const puzzleRestartedEvent
    = initEventType<PuzzleRestartedEventData>("puzzle_restarted");