import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators";
import { ChessPuzzlesMateInOne } from "../puzzles/mate-in-one-puzzles";
import { ChessPuzzlesMateInTwo } from "../puzzles/mate-in-two-puzzles";
import { ChessPuzzlesMateInThree } from "../puzzles/mate-in-three-puzzles";
import { ChessPuzzlesMateInFour } from "../puzzles/mate-in-four-puzzles";
import { ChessPuzzle, ChessPuzzlePlayerToMove } from "../models/chess-puzzle";
import { Chess } from "chess.js";
import { ChessPuzzleParser } from "../puzzles/parser";
import { ChessGame } from "../chess-engine/chess-game";
import { SoundService } from "../sound-service/sound-service";
import { Sounds } from "../sound-service/sounds";
import { ClientService } from "../client-service/client-service";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { StorageService } from "../models/storage-service";
import { UserEventsServiceComposite } from "../user-events/user-events-service-composite";
import { puzzleStartedEvent } from "../user-events/events/puzzle-started-event";
import { puzzleRestartedEvent } from "../user-events/events/puzzle-restarted-event";
import { puzzleAbandonedEvent } from "../user-events/events/puzzle-abandoned-event";
import { puzzleCompletedEvent } from "../user-events/events/puzzle-completed-event";

enum STATE {
    selectPuzzleType = "select-puzzle-type",
    viewPuzzleSetup = "view-puzzle-setup",
    submitPuzzleMove = "submit-puzzle-move",
    invalidMove = "invalid-move",
    viewPuzzleConclusion = "view-puzzle-conclusion"
};

interface PuzzleType {
    title: string;
    setupDescription: string;
    puzzles: ChessPuzzle[];
};

@customElement('puzzle-exercise')
export class PuzzleExerciseComponent extends LitElement {

    // buttons for form input
    private static QUIT_BUTTON = {
        code: 'quit',
        text: 'Quit'
    };
    private static RESTART_BUTTON = {
        code: 'restart',
        text: 'Restart'
    };

    private static puzzleTableStyles = css`
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 1rem auto;
            color: var(--body-color);
            border: 1px solid var(--table-border-color);
        }

        th {
            background-color: var(--table-header-background);
            color: var(--accent-color);
            font-weight: bold;
            text-align: center;
            padding: 0.6rem;
            border: 1px solid var(--table-border-color);
        }

        td {
            text-align: center;
            padding: 0.6rem;
            border: 1px solid var(--table-border-color);
        }

        tr:nth-child(odd) {
            background-color: var(--table-row-odd-background);
        }

        tr:nth-child(even) {
            background-color: var(--table-row-even-background);
        }
    `

    // TODO: as number of puzzles grows, we should lazy load this, or load async
    private state = STATE.selectPuzzleType;

    private puzzles: {[key: string]: PuzzleType} = {
        mateInOne: {
            title: "Mate in One",
            setupDescription: "Find mate in one move.",
            puzzles: ChessPuzzlesMateInOne,
        },
        mateInTwo: {
            title: "Mate in Two",
            setupDescription: "Find mate in two moves.",
            puzzles: ChessPuzzlesMateInTwo,
        },
        mateInThree: {
            title: "Mate in Three",
            setupDescription: "Find mate in three moves.",
            puzzles: ChessPuzzlesMateInThree,
        },
        mateInFour: {
            title: "Mate in Four",
            setupDescription: "Find mate in four moves.",
            puzzles: ChessPuzzlesMateInFour
        }
    };

    private numPiecesRanges = [
        [3, 5],
        [6, 8],
        [9, 10]
    ];

    private soundService = new SoundService();
    private storageService = new LocalStorageService();
    private clientService: ClientService = new ClientService();
    private userEventsService = new UserEventsServiceComposite(
        this.clientService,
        this.storageService
    );

    private chessPuzzleParser = new ChessPuzzleParser();
    private currentPuzzle!: ChessPuzzle;
    private currentPuzzleType!: PuzzleType;
    private currentPuzzleParsedMoves!: (string | string[])[];
    private currentPuzzleStartFen!: string;
    private userInputMoves!: string[];

    private puzzleLastMove!: string;
    private puzzleMoveIndex!: number;
    private chess!: Chess;

    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    render() {
        let inner = html``;

        switch (this.state) {
            case STATE.selectPuzzleType:
                inner = this.renderPuzzleTypeSelect();
                break;
            case STATE.viewPuzzleSetup:
                inner = this.renderPuzzleViewSetup();
                break;
            case STATE.submitPuzzleMove:
                inner = this.renderPuzzleMoveInput();
                break;
            case STATE.invalidMove:
                inner = this.renderInvalidMove();
                break;
            case STATE.viewPuzzleConclusion:
                inner = this.renderPuzzleConclusion();
                break;
        }

        return html`
            ${inner}
        `;
    }

    private renderPuzzleTypeSelect() {
        let puzzleType: PuzzleType;
        let numRange: number[];
    
        const updatePuzzleType = (e: any, type: PuzzleType) => {
            puzzleType = type;
            for (const el of e.target.parentElement.querySelectorAll(".puzzle-type")) {
                (el as any).selected = false;
            }
            const ancestorStyledButton = e.target.closest("styled-button") as any;
            if (ancestorStyledButton) {
                ancestorStyledButton.selected = true;
            }
        };

        const selectRandomPuzzleType = (e: any) => {
            const typeKeys = Object.keys(this.puzzles);
            const selectedTypeKey = typeKeys[Math.floor(Math.random() * typeKeys.length)];
            updatePuzzleType(e, this.puzzles[selectedTypeKey]);
        }
    
        const updateNumRange = (e: any, range: number[]) => {
            numRange = range;
            for (const el of e.target.parentElement.querySelectorAll(".num-range")) {
                (el as any).selected = false;
            }
            const ancestorStyledButton = e.target.closest("styled-button") as any;
            if (ancestorStyledButton) {
                ancestorStyledButton.selected = true;
            }
        };

        const selectRandomNumRange = (e: any) => {
            updateNumRange(e, this.numPiecesRanges[Math.floor(Math.random() * this.numPiecesRanges.length)])
        };
    
        return html`
            <style>
                .puzzle-type-selection {
                    /*gap: 1em;
                    display: flex;
                    flex-direction: column;*/

                    display: flex;
                    justify-content: center;
                    gap: 1em;
                    flex-wrap: wrap;
                }

                #puzzle-table {
                    max-width: 60%;
                }

                #puzzle-table styled-button {
                    --font-size: calc(var(--item-size)* .75);
                }

                .select-buttons {
                    display: flex;
                    justify-content: center;
                    gap: 1em;
                    flex-wrap: wrap;
                }

                @media screen and (max-width: 900px) {
                    #puzzle-table {
                        max-width: 80%;
                    }
                }

                @media screen and (max-width: 650px) {
                    #puzzle-table {
                        max-width: 95%;
                    }
                }
                    
                ${PuzzleExerciseComponent.puzzleTableStyles}
            </style>
            <h2 class="title-header">Start a Puzzle</h2>
            <p>Select the puzzle type and number of pieces you want to work with.</p>
    
            <table id="puzzle-table">
                <tr>
                    <th>Category</th>
                    <th>Number of Pieces</th>
                </tr>
                <tr>
                    <td>
                        <div class="puzzle-type-selection">
                            ${Object.values(this.puzzles).map(type => {
                                return html`
                                    <styled-button class="puzzle-type" @click="${(e: any) => updatePuzzleType(e, type)}">
                                        ${type.title}
                                    </styled-button>
                                `;
                            })}
                            <styled-button class="puzzle-type" @click="${(e: any) => selectRandomPuzzleType(e)}">
                                Random
                            </styled-button>
                        </div>
                    </td>
                    <td>
                        <div class="puzzle-type-selection">
                            ${this.numPiecesRanges.map(range => {
                                return html`
                                    <styled-button class="num-range" @click="${(e: any) => updateNumRange(e, range)}">
                                        ${range[0]}-${range[1]} Pieces
                                    </styled-button>
                                `;
                            })}
                            <styled-button class="num-range" @click="${(e: any) => selectRandomNumRange(e)}">
                                Random
                            </styled-button>
                        </div>
                    </td>
                </tr>
            </table>
    
            <div class="select-buttons">
                <styled-button @click="${() => {
                    this.dispatchExit();
                }}">Go Back</styled-button>
                <styled-button @click="${() => {
                    this.startPuzzle(puzzleType!, numRange!);
                }}">Start Puzzle</styled-button>
            </div>
        `;
    }
    

    private renderPuzzleViewSetup() {
        const { moveCount, piecePositions } = this.currentPuzzle;
        const whitePieces = piecePositions.filter(piece => piece.substring(0, 1).toUpperCase() === piece.substring(0, 1));
        const blackPieces = piecePositions.filter(piece => piece.substring(0, 1).toUpperCase() !== piece.substring(0, 1));
    
        const tableRows: string[][] = [];
        for (let i = 0; i < Math.max(whitePieces.length, blackPieces.length); i++) {
            tableRows.push([
                whitePieces?.[i] || "",
                blackPieces?.[i] || ""
            ]);
        }
    
        return html`
            <style>
                ${PuzzleExerciseComponent.puzzleTableStyles}
    
                h2 {
                    margin-top: 1rem;
                    color: var(--accent-color);
                }
    
                p {
                    color: var(--body-color);
                    margin-bottom: 1rem;
                }
            </style>
            <h2 class="title-header">Puzzle Setup</h2>
            <p><b>${this.currentPuzzle.playerToMove === ChessPuzzlePlayerToMove.white ? "White" : "Black"} to move.</b></p>
            <p>The board is setup with the following pieces:</p>
            <table>
                <tr>
                    <th>White</th>
                    <th>Black</th>
                </tr>
                ${tableRows.map(row => html`
                    <tr>
                        <td>${row[0]}</td>
                        <td>${row[1]}</td>
                    </tr>
                `)}
            </table>
            <p><b>${this.currentPuzzleType.setupDescription}</b></p>

                <styled-button @click="${() => {
                    this.state = STATE.selectPuzzleType;
                    this.requestUpdate();
                }}">Go Back</styled-button>
            <styled-button @click="${() => this.showPuzzleInput()}">Start Puzzle</styled-button>
        `;
    }

    private renderPuzzleMoveInput() {
        return html`
            <style>
                .last-move {
                    font-size: 90%;
                    color: #A0A0A0; /* Subtle gray */
                }
            </style>
            ${this.puzzleLastMove ? html`<p class="last-move">Last move: <b>${this.puzzleLastMove}</b></p><hr/>` : ''}
            <h2 class="title-header">Make Your Move</h2>
            <p>You are playing as ${this.currentPuzzle.playerToMove === ChessPuzzlePlayerToMove.white ? "white" : "black"}.</p>
            <player-move-input-form
                .isWhite="${this.currentPuzzle.playerToMove === ChessPuzzlePlayerToMove.white}"
                @move-submit="${(e: CustomEvent) => this.onPuzzleMoveSubmit(e)}"
                .actionButtons="${[
                    PuzzleExerciseComponent.RESTART_BUTTON,
                    PuzzleExerciseComponent.QUIT_BUTTON
                ]}"
                @action-button-click-restart="${() => {
                    this.restartCurrentPuzzle();
                }}"
                @action-button-click-quit="${() => {
                    this.quitCurrentPuzzle();
                }}"
            ></player-move-input-form>
        `;
    }

    private renderInvalidMove() {
        return html`
            <h2 class="title-header">Wrong Move</h2>
            <p>The move you just played was either invalid or suboptimal.</p>
            <styled-button @click="${() => {
                this.state = STATE.submitPuzzleMove;
                this.requestUpdate();
            }}">Try Again</styled-button>
        `;
    }

    private renderPuzzleConclusion() {
        // TODO: if puzzle wasn't completed by user, show a different header
        return html`
            <style>
                .chess-review-buttons {
                    margin-top: 10px;
                    display: flex;
                    gap: 0.5em;
                    justify-content: center;
                }
            </style>
            <h2 class="title-header">Puzzle Complete</h2>
            <chess-game-review
                .startPosition="${this.currentPuzzleStartFen}"
                .moves="${this.userInputMoves}"
                .isBlack="${this.currentPuzzle.playerToMove !== ChessPuzzlePlayerToMove.white}"
            ></chess-game-review>
            <div class="chess-review-buttons">
                <styled-button class="post-game-action" @click="${() => {
                    this.state = STATE.selectPuzzleType;
                    this.dispatchExit();
                }}">Home</styled-button>
                <styled-button class="post-game-action" @click="${() => {
                    this.state = STATE.selectPuzzleType;
                    this.requestUpdate();
                }}">New Puzzle</styled-button>
            </div>
        `;
    }

    private startPuzzle(puzzleType: PuzzleType, range: number[]) {
        do {
            this.currentPuzzle = puzzleType.puzzles[Math.floor(Math.random() * puzzleType.puzzles.length)];
        } while (this.currentPuzzle.pieceCount < range[0] || this.currentPuzzle.pieceCount > range[1]);

        this.currentPuzzleType = puzzleType;
        this.currentPuzzleParsedMoves = this.chessPuzzleParser.parsePuzzle(this.currentPuzzle);
        this.userInputMoves = [];

        this.state = STATE.viewPuzzleSetup;
        this.puzzleLastMove = "";
        this.puzzleMoveIndex = 0;
        this.chess = ChessPuzzleParser.setupBoardFromPuzzle(
            this.currentPuzzle.piecePositions,
            this.currentPuzzle.playerToMove
        );
        this.currentPuzzleStartFen = this.chess.fen();

        console.log(`[PuzzleExerciseComponent] starting puzzle`, this.currentPuzzle);
        this.soundService.play(Sounds.chessMoveValid);
        this.requestUpdate();

        this.userEventsService.logEvent(
            puzzleStartedEvent({
                puzzleId: this.currentPuzzle.id,
                type: puzzleType.title,
                numPieces: this.currentPuzzle.pieceCount,
                numMoves: this.currentPuzzle.moveCount
            })
        );
    }

    private restartCurrentPuzzle() {
        this.userInputMoves = [];

        this.state = STATE.viewPuzzleSetup;
        this.puzzleLastMove = "";
        this.puzzleMoveIndex = 0;
        this.chess = ChessPuzzleParser.setupBoardFromPuzzle(
            this.currentPuzzle.piecePositions,
            this.currentPuzzle.playerToMove
        );
        this.currentPuzzleStartFen = this.chess.fen();

        console.log(`[PuzzleExerciseComponent] restarting current puzzle`, this.currentPuzzle)
        this.requestUpdate();

        this.userEventsService.logEvent(
            puzzleRestartedEvent({
                puzzleId: this.currentPuzzle.id,
                type: this.currentPuzzleType.title,
                numPieces: this.currentPuzzle.pieceCount,
                numMoves: this.currentPuzzle.moveCount
            })
        );
    }

    private quitCurrentPuzzle() {
        this.userInputMoves = this.currentPuzzleParsedMoves.map(move => {
            if (Array.isArray(move)) {
                return move[0];
            }
            return move;
        });
        this.soundService.play(Sounds.chessCheckmate);

        this.state = STATE.viewPuzzleConclusion;
        this.requestUpdate();

        this.userEventsService.logEvent(
            puzzleAbandonedEvent({
                puzzleId: this.currentPuzzle.id,
                type: this.currentPuzzleType.title,
                numPieces: this.currentPuzzle.pieceCount,
                numMoves: this.currentPuzzle.moveCount
            })
        );
    }

    private onPuzzleMoveSubmit(e: any) {
        const move = e.detail.move;
        const inferredMove = (ChessGame.inferMove(this.chess, move) as any)?.san || "";
        console.log(`[PuzzleExerciseComponent] player move submitted`, { move, inferredMove });

        let puzzleNextMoves = this.currentPuzzleParsedMoves[this.puzzleMoveIndex];
        if (!Array.isArray(puzzleNextMoves)) {
            puzzleNextMoves = [puzzleNextMoves];
        }

        const normalize = (pMove: string) => pMove.replace("+", "").replace("#", "").replace("x", "");
        if (puzzleNextMoves.find(pMove => normalize(pMove) === normalize(inferredMove))) {
            this.userInputMoves.push(inferredMove);
            this.chess.move(inferredMove);

            this.puzzleMoveIndex ++;

            if (this.puzzleMoveIndex === this.currentPuzzleParsedMoves.length) {
                this.state = STATE.viewPuzzleConclusion;
                this.soundService.play(Sounds.chessCheckmate);

                this.userEventsService.logEvent(
                    puzzleCompletedEvent({
                        puzzleId: this.currentPuzzle.id,
                        type: this.currentPuzzleType.title,
                        numPieces: this.currentPuzzle.pieceCount,
                        numMoves: this.currentPuzzle.moveCount
                    })
                );
            } else {
                this.puzzleLastMove = this.currentPuzzleParsedMoves[this.puzzleMoveIndex] as string;
                this.userInputMoves.push(this.puzzleLastMove);
                this.chess.move(this.puzzleLastMove);
                this.puzzleMoveIndex ++;
                this.soundService.play(Sounds.chessMoveValid);
            }

            this.requestUpdate();
        } else {
            console.log(`[PuzzleExerciseComponent] wrong move submitted`, {
                move,
                inferredMove,
                puzzleNextMoves
            });
            this.soundService.play(Sounds.chessMoveBad);
            this.state = STATE.invalidMove;
            this.requestUpdate();
        }
    }

    private showPuzzleInput(): void {
        console.log(`[PuzzleExerciseComponent] showing puzzle input`, this.currentPuzzle)

        this.state = STATE.submitPuzzleMove;
        this.requestUpdate();
    }
    
    private dispatchExit() {
        this.dispatchEvent(new CustomEvent('exit'));
    }
}