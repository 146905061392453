import { initEventType } from "../init-event-type";

export interface PuzzleAbandonedEventData {
    puzzleId: string;
    type: string;
    numPieces: number;
    numMoves: number;
}

export const puzzleAbandonedEvent
    = initEventType<PuzzleAbandonedEventData>("puzzle_abandoned");