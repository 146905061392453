export enum Client {
    webBrowser = "webBrowser",
    androidApp = "androidApp",
    localhost = "localhost",
    androidSmartWatch = "androidSmartWatch"
};

export enum MoveInputType {
    singlePage = "singlePage",
    multiPage = "multiPage"
}

export enum AxisOrientation {
    horizontal = "horizontal",
    vertical = "vertical"
}

export interface ClientConfig {
    body: {
        cssClasses: string[];
        moveInputType: MoveInputType
    };
    chessInput: {
        orientation: AxisOrientation
    };
    gameReview: {
        generateGifEnabled: boolean;
    };
    training: {
        enabled: boolean;
        timedEnabled: boolean;
        puzzlesEnabled: boolean;
    };
    modalBox: {
        coverEntireScreen: boolean;
    };
    controls: {
        window: boolean;
    };
    analytics: {
        logEvents: boolean;
    };
}