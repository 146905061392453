import { initEventType } from "../init-event-type";

export interface PuzzleCompletedEventData {
    puzzleId: string;
    type: string;
    numPieces: number;
    numMoves: number;
}

export const puzzleCompletedEvent
    = initEventType<PuzzleCompletedEventData>("puzzle_completed");