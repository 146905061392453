import './components/styled-button';
import './components/scroll-selector';
import './components/blind-chess-game';
import './components/chess-position-select';
import './components/player-move-input-form';
import './components/modal-layover';
import './components/chess-move-type-select';
import './components/chess-game-review';
import './components/timed-exercise';
import './components/split-hr';
import './components/puzzle-exercise';