import { PlayerType } from "../../models/chess-engine-models";
import { initEventType } from "../init-event-type";

export interface GameCompletedEventData {
    playerOne: PlayerType;
    aiDifficulty: number;
    winner: PlayerType | null;
    movesFen: string[];
    boardFen: string;
    numInvalidMoves: number;
    isResigned: boolean;
    isAutoResigned: boolean;
    isCheckmate: boolean;
    isStalemate: boolean;
    gameStartTimestamp: number;
    gameEndTimestamp: number;
}

export const gameCompletedEvent
    = initEventType<GameCompletedEventData>("game_completed");