export enum ChessPuzzlePlayerToMove {
    white = "white",
    black = "black"
};

export enum ChessPuzzleTag {
    mate
}

export interface ChessPuzzle {
    id: string;
    pieceCount: number;
    moveCount: number;
    piecePositions: string[];
    playerToMove: ChessPuzzlePlayerToMove;
    solutionMoves: (string | string[])[];
    tags: ChessPuzzleTag[];
}