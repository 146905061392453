import { AxisOrientation, ClientConfig, MoveInputType } from "../models/client-config";

export const ANDROID_SMARTWATCH_APP_CONFIG: ClientConfig = {
    body: {
        cssClasses: ['circular-display'],
        moveInputType: MoveInputType.multiPage
    },
    training: {
        enabled: false,
        timedEnabled: false,
        puzzlesEnabled: false
    },
    chessInput: {
        orientation: AxisOrientation.horizontal
    },
    modalBox: {
        coverEntireScreen: true
    },
    gameReview: {
        generateGifEnabled: true
    },
    controls: {
        window: false
    },
    analytics: {
        logEvents: true
    }
};