import GIF from "gif.js";

export class GifCompilerService {
    private gif: GIF;
    private reusableCanvas: HTMLCanvasElement | null = null;
    private ctx: CanvasRenderingContext2D | null = null;

    constructor(private frameDelay = 1_000) {
        this.gif = new GIF({
            workers: 2,
            quality: 10,
            workerScript: "/gif.worker.js",
        });
    }

    /**
     * Adds a frame to the GIF. Accepts either a Blob or an HTMLCanvasElement.
     * @param input - The input frame (Blob or Canvas).
     */
    addFrame(input: Blob | HTMLCanvasElement): Promise<void> {
        if (input instanceof HTMLCanvasElement) {
            return this.addCanvasFrame(input);
        } else {
            return this.addBlobFrame(input);
        }
    }

    /**
     * Handles adding a canvas frame to the GIF.
     * @param canvas - The canvas element to add.
     */
    private async addCanvasFrame(canvas: HTMLCanvasElement): Promise<void> {
        this.gif.addFrame(canvas, { delay: this.frameDelay });
    }

    /**
     * Handles adding a blob frame to the GIF.
     * Converts the Blob to an ImageBitmap, draws it onto a reusable canvas, and adds it.
     * @param blob - The blob to process and add as a frame.
     */
    private async addBlobFrame(blob: Blob): Promise<void> {
        const imageBitmap = await createImageBitmap(blob);

        // Initialize the reusable canvas and context if not already done
        if (!this.reusableCanvas || !this.ctx) {
            this.reusableCanvas = document.createElement("canvas");
            this.reusableCanvas.width = imageBitmap.width;
            this.reusableCanvas.height = imageBitmap.height;
            this.ctx = this.reusableCanvas.getContext("2d") as CanvasRenderingContext2D;
        }

        // Draw the imageBitmap onto the reusable canvas
        this.ctx.clearRect(0, 0, this.reusableCanvas.width, this.reusableCanvas.height);
        this.ctx.drawImage(imageBitmap, 0, 0);

        // Add the reusable canvas to the GIF
        this.gif.addFrame(this.reusableCanvas, { delay: this.frameDelay });

        // Cleanup the ImageBitmap
        imageBitmap.close();
    }

    /**
     * Compiles and returns the final GIF as a Blob.
     * @returns A Promise resolving to the final GIF Blob.
     */
    compileGif(): Promise<Blob> {
        return new Promise((resolve, reject) => {
            this.gif.on("finished", (blob) => resolve(blob));
            // @ts-ignore
            this.gif.on("error", (error) => reject(error));
            this.gif.render();
        });
    }
}
