import { html } from "lit";
import { TimedExerciseQuestion, TimedExerciseQuestionService } from "../models/timed-exercise";

export class PieceCaptureExerciseQuestionService implements TimedExerciseQuestionService {

    getInfo(): { title: string; description: string; } {
        return {
            title: "Piece Capture Exercises",
            description: "Determine if a piece can capture another piece on a given position."
        };
    }

    getQuestion(): TimedExerciseQuestion {
        const pieceOne = this.getRandomPiece();
        const positionOne = this.getRandomPosition();
        const positionTwo = this.getRandomPosition();
        const canCapture = this.canPieceCapture(pieceOne, positionOne, positionTwo);

        return {
            prompt: html`
                <style>
                    .cell {
                        font-size: 90%;
                        color: #A0A0A0; /* Subtle gray */
                    }
                </style>
                Can a <span class="cell">${pieceOne.toLowerCase()}</span> on <span class="cell">${positionOne}</span> capture
                a piece on <span class="cell">${positionTwo}</span>?
            `,
            choices: [
                {
                    text: "Yes",
                    isCorrect: canCapture
                },
                {
                    text: "No",
                    isCorrect: !canCapture
                }
            ]
        };
    }

    private getRandomPiece(): string {
        const pieces = ["King", "Queen", "Rook", "Bishop", "Knight", "Pawn"];
        return pieces[Math.floor(Math.random() * pieces.length)];
    }

    private getRandomPosition(): string {
        const col = ["a", "b", "c", "d", "e", "f", "g", "h"][Math.floor(Math.random() * 8)];
        const row = Math.floor(Math.random() * 8) + 1;
        return `${col}${row}`;
    }

    private canPieceCapture(piece: string, positionOne: string, positionTwo: string): boolean {
        const colOne = positionOne.charCodeAt(0) - "a".charCodeAt(0) + 1;
        const rowOne = parseInt(positionOne[1], 10);
        const colTwo = positionTwo.charCodeAt(0) - "a".charCodeAt(0) + 1;
        const rowTwo = parseInt(positionTwo[1], 10);

        const colDiff = Math.abs(colOne - colTwo);
        const rowDiff = Math.abs(rowOne - rowTwo);

        switch (piece) {
            case "King":
                return colDiff <= 1 && rowDiff <= 1;
            case "Queen":
                return colDiff === rowDiff || colOne === colTwo || rowOne === rowTwo;
            case "Rook":
                return colOne === colTwo || rowOne === rowTwo;
            case "Bishop":
                return colDiff === rowDiff;
            case "Knight":
                return (colDiff === 2 && rowDiff === 1) || (colDiff === 1 && rowDiff === 2);
            case "Pawn":
                return colDiff === 1 && rowDiff === 1 && rowTwo > rowOne;
            default:
                return false;
        }
    }
}
