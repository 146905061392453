import { initEventType } from "../init-event-type";

export interface TimedExerciseCompletedEventData {
    title: string;
    numTotal: number;
    numCorrect: number;
    numIncorrect: number;
    endedEarly: boolean;
}

export const timedExerciseCompletedEvent
    = initEventType<TimedExerciseCompletedEventData>("timed_exercise_completed");