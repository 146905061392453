import { UserEvent } from "../models/analytics-event";
import { ClientService } from "../client-service/client-service";
import { UserEventsService } from "../models/user-events-service";
import { gameStartedEvent } from "./events/game-started-event";
import { gameCompletedEvent } from "./events/game-completed-event";
import { gameMoveSubmittedEvent } from "./events/game-move-submitted-event";
import { puzzleStartedEvent } from "./events/puzzle-started-event";
import { puzzleCompletedEvent } from "./events/puzzle-completed-event";
import { puzzleAbandonedEvent } from "./events/puzzle-abandoned-event";
import { puzzleRestartedEvent } from "./events/puzzle-restarted-event";
import { timedExerciseStartedEvent } from "./events/timed-exercise-started-event";
import { timedExerciseCompletedEvent } from "./events/timed-exercise-completed-event";

export class GoogleUserEventsService implements UserEventsService {

    private static instance: GoogleUserEventsService;
    private static TRACKING_ID = "G-KHBHBNMQWM";
    private initPromise!: Promise<void>;

    private static ALLOWED_EVENTS = new Set([
        gameStartedEvent,
        gameCompletedEvent,
        gameMoveSubmittedEvent,
        puzzleStartedEvent,
        puzzleCompletedEvent,
        puzzleAbandonedEvent,
        puzzleRestartedEvent,
        timedExerciseStartedEvent,
        timedExerciseCompletedEvent
    ].map(fn => fn({} as any).eventName));

    constructor(
        private clientService: ClientService
    ) {
        if (GoogleUserEventsService.instance) {
            return GoogleUserEventsService.instance;
        }

        GoogleUserEventsService.instance = this;
        this.init();
    }

    async logEvent(event: UserEvent<any>): Promise<void> {
        if (!this.clientService.getConfig().analytics.logEvents) {
            return;
        }

        if (!GoogleUserEventsService.ALLOWED_EVENTS.has(event.eventName)) {
            return;
        }

        await this.initPromise;

        console.log("[GoogleUserEventsService] sending event", event);

        if ((window as any).gtag) {
            (window as any).gtag('event', event.eventName, event.data);
        } else {
            console.warn("[GoogleUserEventsService] failed to send event due to lack of gtag", event);
        }
    }

    private async init(): Promise<void> {
        this.initPromise = new Promise((resolve) => {
            if (!this.clientService.getConfig().analytics.logEvents) {
                resolve();
                return;
            }
            console.log("[GoogleUserEventsService] loading Google Analytics");

            // Initialize the dataLayer and gtag function
            const global = window as any;
            global.dataLayer = global.dataLayer || [];
            global.gtag = function() {
                global.dataLayer.push(arguments);
                console.log("[GoogleAnalytics] datalayers pushed", arguments)
            }

            // Load the Google Analytics script asynchronously
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${GoogleUserEventsService.TRACKING_ID}`;

            // Load the Google Analytics tracking ID
            script.onload = () => {
                global.gtag('js', new Date());
                global.gtag('config', GoogleUserEventsService.TRACKING_ID);
                resolve();
            };

            document.head.appendChild(script);
        });
    }
}