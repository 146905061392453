import { html } from "lit";
import { TimedExerciseQuestion, TimedExerciseQuestionService } from "../models/timed-exercise";

interface Cell {
    pos: string;
    col: number;
    row: number;
}

export class DiagonalTimedExerciseQuestionService implements TimedExerciseQuestionService {

    getInfo(): { title: string; description: string; } {
        return {
            title: "Diagonal Exercises",
            description: "Determine if two or more cells fall on the same diagonal."
        };
    }

    getQuestion(): TimedExerciseQuestion {
        const isSame: boolean = Math.random() < 0.5;
        const numInPrompt: number = Math.random() < 0.2 ? 3 : 2;

        const choices: string[] = this.generateChoices(isSame, numInPrompt);

        const choicesTexts: unknown[] = this.formatChoicesText(choices);

        return {
            prompt: html`
                <style>
                    .cell {
                        font-size: 90%;
                        color: #A0A0A0; /* Subtle gray */
                    }
                </style>
                Are ${choicesTexts} in the same diagonal?
            `,
            choices: [
                {
                    text: "Yes",
                    isCorrect: isSame
                },
                {
                    text: "No",
                    isCorrect: !isSame
                }
            ]
        };
    }

    private generateChoices(isSame: boolean, numCells: number): string[] {
        const firstChoice: Cell = this.getRandomPosition();
        const choices: Set<Cell> = new Set([firstChoice]);

        let remainingCells: Cell[] = isSame
            ? this.getDiagonalCells(firstChoice)
            : this.getNonDiagonalCells(firstChoice);

        while (choices.size < numCells) {
            const nextCell: Cell = this.selectRandomCell(remainingCells);
            remainingCells = remainingCells.filter(cell => cell.pos !== nextCell.pos);

            if (isSame) {
                if (!Array.from(choices).every(existingCell => this.isInSameDiagonal(existingCell, nextCell))) {
                    continue;
                }
                if (Array.from(choices).some(existingCell => this.isInSameRowOrColumn(existingCell, nextCell))) {
                    continue;
                }
            } else {
                if (Array.from(choices).some(existingCell => this.isInSameDiagonal(existingCell, nextCell))) {
                    continue;
                }
            }

            choices.add(nextCell);
        }

        return Array.from(choices).map(cell => cell.pos);
    }

    private formatChoicesText(choices: string[]): unknown[] {
        const choicesTexts: unknown[] = [html``];
        const includeComma: boolean = choices.length > 2;

        choices.forEach((choice, index) => {
            choicesTexts.push(html`<span class="cell">${choice}</span>`);

            if (index === choices.length - 2) {
                choicesTexts.push(html`${includeComma ? "," : ""} and `);
            } else if (index < choices.length - 2 && includeComma) {
                choicesTexts.push(html`, `);
            }
        });

        return choicesTexts;
    }

    private getRandomPosition(): Cell {
        const colInd: number = Math.floor(Math.random() * 8);
        const randomCol: string = ["a", "b", "c", "d", "e", "f", "g", "h"][colInd];
        const randomRow: number = Math.floor(Math.random() * 8) + 1;

        return {
            pos: `${randomCol}${randomRow}`,
            col: colInd + 1,
            row: randomRow
        };
    }

    private getDiagonalCells(target: Cell): Cell[] {
        const diagonalCells: Cell[] = [];

        for (let i = 1; i <= 8; i++) {
            for (let j = 1; j <= 8; j++) {
                if (Math.abs(target.row - i) === Math.abs(target.col - j)) {
                    const col: string = ["a", "b", "c", "d", "e", "f", "g", "h"][j - 1];
                    diagonalCells.push({
                        pos: `${col}${i}`,
                        col: j,
                        row: i
                    });
                }
            }
        }

        return diagonalCells;
    }

    // get cells that aren't in the same diagonal, same row, or same col
    private getNonDiagonalCells(target: Cell): Cell[] {
        return Array.from({ length: 8 }, (_, colInd) => {
            return Array.from({ length: 8 }, (_, rowInd) => {
                const col: string = ["a", "b", "c", "d", "e", "f", "g", "h"][colInd];
                return {
                    pos: `${col}${rowInd + 1}`,
                    col: colInd + 1,
                    row: rowInd + 1
                };
            });
        }).flat().filter(cell => {
            return !this.isInSameDiagonal(cell, target) && !this.isInSameRowOrColumn(cell, target);
        });
    }

    private selectRandomCell(cells: Cell[]): Cell {
        const randomIndex: number = Math.floor(Math.random() * cells.length);
        return cells[randomIndex];
    }

    private isInSameDiagonal(cellA: Cell, cellB: Cell): boolean {
        return Math.abs(cellA.row - cellB.row) === Math.abs(cellA.col - cellB.col);
    }

    private isInSameRowOrColumn(cellA: Cell, cellB: Cell): boolean {
        return cellA.col === cellB.col || cellA.row === cellB.row;
    }
}