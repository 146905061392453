import { AxisOrientation, ClientConfig, MoveInputType } from "../models/client-config";

export const WEB_BROWSER_CONFIG: ClientConfig = {
    body: {
        cssClasses: [],
        moveInputType: MoveInputType.singlePage
    },
    training: {
        enabled: true,
        timedEnabled: true,
        puzzlesEnabled: true
    },
    chessInput: {
        orientation: AxisOrientation.vertical
    },
    modalBox: {
        coverEntireScreen: false
    },
    gameReview: {
        generateGifEnabled: true
    },
    controls: {
        window: false
    },
    analytics: {
        logEvents: true
    }
};