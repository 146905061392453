import { html } from "lit";

export const TEXT_ABOUT = html`
<style>
    #how-to-play {
        text-align: left;
    }

    #tagline {
        text-align: center;
    }

    h1, h2 {
        text-align: center;
    }

    h3 {
        margin-top: 2em;
    }
</style>
<div id="how-to-play">
    <h1>Blindfold Chess</h1>
    <p id="tagline">
        This is a simple app for playing pure blindfold chess. No boards, no hints, no undo's.
        <br>
        <br>
        <b>Note:</b> <i>this app is under active development and is undergoing changes and bug fixes!</i>
    </p>
    <br/>

    <h2>How to Play</h2>
    <h3>Starting the Game</h3>
    <p>First, select the pieces you want to play as, or select the random option if you'd like
        the system to decide. Then, select the difficulty level and press "Start Game!".
    
    <h3>Playing the Game</h3>
    <p>
        When it's your turn to play a move, you'll be presented with inputs that will allow you to specify the 
        move you'd like to make. There are two different kinds of input:
    </p>
    <ul>
        <li><b>Piece to Position: </b>state the type of piece you'd like to move, such as pawn, and the position you'd like to move it to, such as d4.
            This is suitable for most moves you'd need to make.</li>
        <li><b>Position to Position: </b>state the current position of the piece you want to move and the position you want to move it to.
            This is suitable in situations where multiple pieces of the same type can move to the same position.</li>
    </ul>
    
    <h3>Castling</h3>
    <p>To castle, select the king piece and set its position to the square it will occupy once the castling is complete.</p>

    <h3>Promoting Pawns</h3>
    <p>The interface will show an additional piece selection component when you're trying to push a pawn to its opposite rank.
        More specifically:
    <p>
    <ul>
        <li>If you input a move via the piece to position input, the piece is a pawn, and the row is the first/last row, the additional piece selection input will appear.</li>
        <li>If you input a move via the position to position input and the row is the first/last row, the additional piece selection input will appear with an additional
            null item. If you're not moving a pawn, you'll need to select the null entry.</li>
    </ul>

    <br/>
    <h2>Training</h2>
    <h3>Puzzles</h3>
    <p>
        This app offers a selection of puzzles of mate in 1, 2, 3, and 4. You can select the type of puzzle and the
        number of pieces involved, then proceed with the puzzle. The app will denote all pieces and their positions,
        then ask you to enter moves in the same way you'd enter moves in a game. Once the puzzle is over, you can
        view it on a board.
    </p>

    <h3>Exercises</h3>
    <p>
        This app offers a variety of timed training exercises, including a cell color exercise which challenges you
        to correctly identify if any given cell is a light or dark cell, and a diagonal exercise, which challenges you
        to identify if two or more cells fall on the same diagonal. All exercises are timed and present challenges
        as a multiple choice selection. When the challenge is over, you'll be presented with the results.
    </p>

    <br/>
    <h2>About the App</h2>
    <p>
        I created this app because, surprisingly, I couldn't find any apps that include the functionality to play a 
        purely blind game of chess. The chess.com app has a mode where the pieces are invisible, but the board is still
        present. This isn't bad in its own right, but one of the difficulties of blindfold chess is actually visualizing
        the board behind the pieces, so this isn't the same. I've also come across references to apps that help you train
        yourself to play blindfold chess, but I wasn't able to verify if these let you play full games.
    </p>

    <p>
        Right now, I'm still working on improving the app's playability and ironing out some of the bugs. Once that's done,
        I plan on making this available as an Android app, then with a bit more work, as an Android smartwatch app.
    </p>

    <p>
        The AI is powered by <a href="https://stockfishchess.org/" target="_blank">Stockfish</a>. I wrote a chess AI myself
        a few years ago, but I decided not to use it due to the amount of effort it would take to make it efficient
        and portable enough for this project. I might return to it or make a new one at some point in the future.
    </p>
</div>
`;