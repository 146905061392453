import { PlayerType } from "../../models/chess-engine-models";
import { initEventType } from "../init-event-type";

export interface GameStartedEventData {
    playerOne: PlayerType;
    aiDifficulty: number;
    gameStartTimestamp: number;
}

export const gameStartedEvent
    = initEventType<GameStartedEventData>("game_started");