import { Chess, Square } from "chess.js";
import { ChessPuzzle, ChessPuzzlePlayerToMove } from "../models/chess-puzzle";

export class ChessPuzzleParser {

    private chess!: Chess;

    /**
     * Parses a ChessPuzzle and returns all solution moves in standard algebraic notation (SAN).
     * @param puzzle The ChessPuzzle to parse.
     * @returns A list of moves with alternatives included for compound moves.
     */
    parsePuzzle(puzzle: ChessPuzzle): (string | string[])[] {
        try {
            return this.doParsePuzzle(puzzle);
        } catch (err) {
            console.error(`[ChessPuzzleParser] error parsing puzzle`, puzzle, err);
            throw err;
        }
    }

    private doParsePuzzle(puzzle: ChessPuzzle): (string | string[])[] {
        const { piecePositions, solutionMoves, playerToMove } = puzzle;

        // Initialize the board based on piece positions
        this.chess = ChessPuzzleParser.setupBoardFromPuzzle(piecePositions, playerToMove);

        const parsedMoves: (string | string[])[] = [];

        for (const move of solutionMoves) {
            if (Array.isArray(move)) {
                // Parse all alternatives for a compound move
                const parsedAlternatives = move.map((subMove) => this.parseMove(subMove));
                parsedMoves.push(parsedAlternatives);

                // Make the first valid move to continue progressing
                this.chess.move(parsedAlternatives[0]);
            } else {
                // Parse and apply a single move
                const parsedMove = this.parseMove(move);
                parsedMoves.push(parsedMove);
                this.chess.move(parsedMove);
            }
        }

        return parsedMoves;
    }

    /**
     * Sets up the chess board manually using piece positions.
     * @param piecePositions An array of piece positions in "PieceSquare" format.
     * @param playerToMove The player to move ("w" or "b").
     */
    static setupBoardFromPuzzle(piecePositions: string[], playerToMove: ChessPuzzlePlayerToMove): Chess {
        const chess = new Chess();
        chess.clear();

        for (const position of piecePositions) {
            // Handle extended notation like "Ra8e8"
            const match = position.match(/^([KQRBNP])?([a-h][1-8])([a-h][1-8])?$/i);
            if (!match) {
                throw new Error(`Invalid piece position format: ${position}`);
            }

            const pieceType = match[1]?.toLowerCase(); // Piece type (k, q, r, b, n, p)
            const startSquare = match[2].toLowerCase(); // Initial square (e.g., "a8")
            const color = match[1]?.toUpperCase() === match[1] ? 'w' : 'b';

            // Place the piece on the start square
            if (pieceType && startSquare) {
                chess.put({ type: pieceType as any, color }, startSquare as Square);
            }
        }

        // Set the turn based on the player to move
        const fen = chess.fen().replace(/ [wb] /, ` ${playerToMove.substring(0, 1)} `);
        chess.load(fen);
        console.log(`[ChessPuzzleParser] board setup`, {
            fen,
            piecePositions
        });

        return chess;
    }


    /**
     * Parses a move into its standard algebraic notation (SAN).
     * Handles implicit captures, promotions with specified pieces, and moves without "x".
     * @param move The move string to validate and parse.
     * @returns The move in SAN format.
     * @throws If the move is invalid in the current position.
     */
    private parseMove(move: string): string {
        // Get all valid moves in verbose mode
        const validMoves = this.chess.moves({ verbose: true });

        // Match the move against valid moves based on target square, source square, piece type, and promotion
        const matchedMove = validMoves.find((validMove) => {
            // Match moves specifying source, target, and promotion (e.g., "pc2c1=r")
            const promotionMatch = move.match(/^p?([a-h][1-8])([a-h][1-8])=([qrbn])$/i);
            if (promotionMatch) {
                const [, from, to, promotionPiece] = promotionMatch;
                return (
                    validMove.from.toLowerCase() === from.toLowerCase() &&
                    validMove.to.toLowerCase() === to.toLowerCase() &&
                    validMove.promotion === promotionPiece.toLowerCase()
                );
            }

            // Match moves specifying source and target (e.g., "Rb6c6")
            const match = move.match(/^([KQRBNP])?([a-h][1-8])([a-h][1-8])$/i);
            if (match) {
                const [, piece, from, to] = match;
                return (
                    validMove.from.toLowerCase() === from.toLowerCase() &&
                    validMove.to.toLowerCase() === to.toLowerCase() &&
                    (!piece || validMove.piece === piece.toLowerCase())
                );
            }

            // Match basic moves by target square only
            if (move.toLowerCase() === validMove.to.toLowerCase()) {
                return true;
            }

            // Match moves with explicit piece type (e.g., "Ra1")
            const pieceType = move.charAt(0).toLowerCase();
            if (validMove.piece === pieceType && move.substring(1).toLowerCase() === validMove.to.toLowerCase()) {
                return true;
            }

            // Match promotions (e.g., "pf1=q" or "f1q")
            if (validMove.flags.includes('p')) {
                // Handle "pf1=q" format
                const promoMatch = move.match(/^p?([a-h][1-8])=([qrbn])$/i);
                if (promoMatch) {
                    const [, targetSquare, promotionPiece] = promoMatch;
                    return (
                        validMove.to.toLowerCase() === targetSquare.toLowerCase() &&
                        validMove.promotion === promotionPiece.toLowerCase()
                    );
                }

                // Handle compact format (e.g., "f1q")
                const promotionPiece = move.slice(-1).toLowerCase(); // Last char is the promoted piece
                const targetSquare = move.slice(0, -1).toLowerCase(); // Rest is the target square
                return (
                    validMove.to.toLowerCase() === targetSquare &&
                    validMove.promotion === promotionPiece
                );
            }

            return false;
        });

        if (!matchedMove) {
            console.warn(`[ChessPuzzleParser] couldn't match move`, {
                move,
                validMoves
            });
            throw new Error(`Could not match move: ${move}`);
        }

        return matchedMove.san; // Return the matched move in SAN format
    }

}