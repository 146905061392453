import { initEventType } from "../init-event-type";

export interface PuzzleStartedEventData {
    puzzleId: string;
    type: string;
    numPieces: number;
    numMoves: number;
}

export const puzzleStartedEvent
    = initEventType<PuzzleStartedEventData>("puzzle_started");