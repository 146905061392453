import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Chess } from 'chess.js';
import { SoundService } from '../sound-service/sound-service';
import { Sounds } from '../sound-service/sounds';
import { ImageCaptureService } from '../image-capture/image-capture-service';
import { GifCompilerService } from '../image-capture/gif-compiler-service';

@customElement('chess-game-review')
export class ChessGameReview extends LitElement {

    @property({ type: Array }) startPosition: string = "start";
    @property({ type: Array }) moves: string[] = [];
    @property({ type: Boolean }) isBlack: boolean = false;

    private chess = new Chess();
    private currentMoveIndex = 0;
    private board: any;
    private soundService = new SoundService();
    private imageCaptureService = new ImageCaptureService();

    private animationsDisabled: boolean = false;
    private gifPromise?: Promise<Blob>;

    static styles = css`
        .board-container {
            width: min(50dvw, 60dvh);
            background-color: black;
        }

        .move-controls {
            margin-top: 10px;
            display: flex;
            gap: 0.5em;
        }

        .force-visible {
            display: block !important;
        }

        .gif-render {
            color: white;
            display: none;
            margin: 0;
            padding: 0.5em;
            font-size: 0.9em;
        }

        .force-no-border * {           /* A general rule for direct children if needed */
            border: none !important; /* Remove borders */
            box-shadow: none !important; /* Remove shadows */
            outline: none !important; /* Remove outlines */
        }

        .child-flex-col > * {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @media screen and (max-width: 1500px) {
            .board-container {
                width: min(70dvw, 70dvh);
            }
        }
    `;

    createRenderRoot() {
        return this; // Use light DOM to ensure ChessBoard.js can access the element.
    }

    connectedCallback(): void {
        super.connectedCallback();
        window.addEventListener('resize', () => this.board.resize());
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        window.removeEventListener('resize', () => this.board.resize());
    }

    firstUpdated() {
        // Initialize the ChessBoard
        this.board = (window as any).Chessboard(this.querySelector('#board'), {
            position: this.startPosition,
            orientation: this.isBlack ? 'black' : 'white',
        });
        this.chess = this.startPosition === "start"
            ? new Chess()
            : new Chess(this.startPosition);
    }

    private updateBoard() {
        const fen = this.chess.fen();

        if (this.animationsDisabled) {
            // Force-update the board state without animations
            this.board.position(fen, false); // Pass 'false' to disable animations
        } else {
            // Default behavior (with animations)
            this.board.position(fen);
        }
    }

    private moveForward(disableSound = false) {
        if (this.currentMoveIndex < this.moves.length) {
            const move = this.moves[this.currentMoveIndex];

            this.chess.move(move);
            this.currentMoveIndex++;
            this.updateBoard();

            if (!disableSound) {
                this.soundService.play(Sounds.chessMoveValid);
                if (this.chess.isGameOver()) {
                    this.soundService.play(Sounds.chessCheckmate);
                }
            }
        }
    }

    private moveBackward(disableSound = false) {
        if (this.currentMoveIndex > 0) {
            this.chess.undo();
            this.currentMoveIndex--;
            this.updateBoard();

            if (!disableSound) {
                this.soundService.play(Sounds.chessMoveValid);
            }
        }
    }


    render() {
        return html`
            <style>
                ${ChessGameReview.styles}
            </style>
            <p class="gif-render">
                Please wait while we render your gif.
            </p>
            <div class="board-container">
                <div id="board"></div>
                <p class="gif-render">
                    A blindfold game of chess played on www.playblindfoldchess.com
                </p>
            </div>

            <div class="move-controls">
                <styled-button @click="${() => this.moveBackward()}">←</styled-button>
                <styled-button @click="${() => this.moveForward()}">→</styled-button>
            </div>
            `;
    }

    generateGif(): Promise<Blob> {
        if (this.gifPromise) {
            console.log(`[ChessGameReview] already generating gif`);
            return this.gifPromise;
        }

        const promise = this.doGenerateGif();
        this.gifPromise = promise.finally(() => this.gifPromise = void 0);
        return promise;
    }

    private async doGenerateGif(): Promise<Blob> {
        const soundDisabled = true; // Flag to disable sounds
        this.animationsDisabled = true; // Disable animations

        const gifTaglineEls = [...this.querySelectorAll(".gif-render")] as HTMLElement[];
        const board = this.querySelector(".board-container") as HTMLElement;

        try {
            console.log("[ChessGameReview] Generating GIF...");
            gifTaglineEls.forEach(el => el.classList.add("force-visible"));
    
            // Step 1: Rewind the board to the start
            while (this.currentMoveIndex > 0) {
                this.moveBackward(soundDisabled);
            }
            await new Promise((r) => setTimeout(r, 50));
    
            // Step 2: Capture frames and add to GIF
            const gifCompilerService = new GifCompilerService(); // 500ms delay per frame
            board.classList.add("force-no-border");
            board.classList.add("child-flex-col");

            // add first image
            let imgData = await this.imageCaptureService.captureElementToCanvas(board);
            gifCompilerService.addFrame(imgData);

            do {
                this.moveForward(soundDisabled); // Move to the next position
                await new Promise((r) => setTimeout(r, 50));

                imgData = await this.imageCaptureService.captureElementToCanvas(board);
                gifCompilerService.addFrame(imgData);
            } while (this.currentMoveIndex < this.moves.length);
    
            // Step 3: Compile the GIF
            console.log("[ChessGameReview] Compiling GIF...");
            return await gifCompilerService.compileGif();
    
        } catch (error) {
            console.error("[ChessGameReview] Error generating GIF:", error);
            throw error;
    
        } finally {
            // Step 4: Restore animations
            console.log("[ChessGameReview] Restoring board state...");
            this.animationsDisabled = false;
            gifTaglineEls.forEach(el => el.classList.remove("force-visible"));
            board.classList.remove("force-no-border");
            board.classList.remove("child-flex-col");
        }
    }
}